<template>
  <div class="home-container details">
    <div class="headerTitle">
      <h2 class="title">{{ message.parTitle }}</h2>
      <p class="date">发布时间: {{ message.pubDtm }}</p>
    </div>
    <p
      class="content-data"
      v-html="message.parContent"
      style="font-size: 16px"
    ></p>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return { message: [] };
  },
  mounted() {
    // 详情页接口
    const { row_pkValue } = this.$route.query;
    this.$axios({
      method: "post",
      url: "/laf/laf/lafParty/find.ajax",
      data: {
        pageNo: 1,
        _entityName: "org.aweto.laf.entity.LafParty",
        pkValue: row_pkValue,
      },
    })
      .then((res) => {
        console.log(res);
        this.message = res.recorder;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.details {
  margin-top: 40px;
  margin-bottom: 40px;
  .content-data {
    text-align: left;
    width: 100%;
    min-height: 500px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    overflow-y: auto;
    padding: 20px 20px 20px 20px;
    word-wrap: break-word;
    white-space: normal;
    word-break: break-all;
  }
  .content-data::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  .content-data::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e5e5e5;
  }
  .content-data::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #e5e5e5;
    border-radius: 0;
    background: #e5e5e5;
  }
  .headerTitle {
    width: 100%;
    height: 100px;
    border-top: 3px solid #cd242a;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding-top: 20px;
    text-align: center;
    .title {
      font-size: 25px;
      text-align: center;
      color: #cd242a;
    }
    .date {
      font-size: 14px;
      text-align: center;
      color: #bbb;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }

  .nextPage {
    height: 68px;
    line-height: 68px;
    border-top: 2px solid #e5e5e5;
    & :nth-child(1) {
      margin-left: 33px;
    }
    & :nth-child(2) {
      float: right;
      margin-right: 35px;
    }
  }
}
::v-deep {
  .container,
  img {
    max-width: 100%;
  }
}
</style>